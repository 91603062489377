// src/components/PageBuilder/MainElements/Module/Modules/TestModule/TestModule.js

import React, { useState, useEffect } from 'react';
import Module from '../../Module';
import './TestModule.css';

const TestModule = ({
  moduleId,
  settings,
  updateModuleSettings,
  onDuplicate,
  onDelete,
  addModuleBelow,
}) => {
  // Setze den Standardinhalt
  const defaultContent = 'Ich bin ein Testmodul.';
  const [content, setContent] = useState(settings.content || defaultContent);

  // Speichere den Standardinhalt in den settings, falls noch nicht vorhanden
  useEffect(() => {
    if (!settings.content) {
      if (updateModuleSettings) {
        updateModuleSettings({ ...settings, content: defaultContent });
      }
    }
  }, []);

  const handleContentChange = (e) => {
    const newContent = e.target.value;
    setContent(newContent);
    if (updateModuleSettings) {
      updateModuleSettings({ ...settings, content: newContent });
    }
  };

  return (
    <Module
      moduleId={moduleId}
      settings={settings}
      updateModuleSettings={updateModuleSettings}
      onDuplicate={onDuplicate}
      onDelete={onDelete}
      addModuleBelow={addModuleBelow}
    >
      <div className="test-module">
        <h3>Hallo!</h3>
        <textarea value={content} onChange={handleContentChange} />
      </div>
    </Module>
  );
};

export default TestModule;
