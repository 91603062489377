// LinkSettings.js
import React from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const LinkSettings = ({ settings, onChange }) => {
    return (
        <AccordionWrapper title="Link">
            <div className="settingCategory link-settings">
                <p className="settingCategoryDescription">Einen Link für das gesamte Element festlegen.</p>
                <input 
                    type="text" 
                    value={settings.link || ''} 
                    onChange={(e) => onChange({ ...settings, link: e.target.value })}
                    placeholder="URL eingeben"
                />
            </div>
        </AccordionWrapper>
    );
};

export default LinkSettings;
