// DragDropElementButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import './DragDropElementButton.css';

const DragDropElementButton = ({ color, className, dragRef }) => {
  return (
    <button
      ref={dragRef} // Verbinde das Drag-Ref hier
      style={{ backgroundColor: color }}
      className={`dragDropElementButton ${className || ''}`}
    >
      <FontAwesomeIcon icon={faArrowsAlt} />
    </button>
  );
};

export default DragDropElementButton;
