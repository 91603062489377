// AddElementButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './AddElementButton.css';

const AddElementButton = ({ onClick, color, className }) => {
    return (
        <button 
            onClick={onClick} 
            style={{ backgroundColor: color }} 
            className={`addElementButton ${className}`} // Hier wird die Klasse hinzugefügt
        >
            <FontAwesomeIcon icon={faPlus} /> {/* Icon statt Label */}
        </button>
    );
};

export default AddElementButton;
