// src/components/AdminDashboard/Content/Content.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageList from '../PageList/PageList';
import NavigationEditor from '../NavigationEditor/NavigationEditor';
import TemplateLibrary from '../TemplateLibrary/TemplateLibrary';
import MediaLibrary from '../MediaLibrary/MediaLibrary';
import './Content.css'; // Optional für Styling

function Content() {
  return (
    <div className="content">
      <Routes>
        <Route path="/" element={<h2>Bitte wählen Sie eine Option aus dem Menü.</h2>} />
        <Route path="pages" element={<PageList />} />
        <Route path="navigation" element={<NavigationEditor />} />
        <Route path="templates" element={<TemplateLibrary />} />
        <Route path="media" element={<MediaLibrary />} />
      </Routes>
    </div>
  );
}

export default Content;
