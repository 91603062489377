// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Start from './components/Start/Start';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import PageBuilder from './components/PageBuilder/PageBuilder';

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/admin/*" element={<AdminDashboard />} />
        {/* PageBuilder innerhalb des Admin-Bereichs */}
        <Route path="/admin/page-builder/:id?" element={<PageBuilder />} />
      </Routes>
    </Router>
  );
}

export default App;
