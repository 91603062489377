import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './DeleteElementButton.css';

const DeleteElementButton = ({ onClick, color, className }) => {
    return (
        <button 
            onClick={onClick} 
            style={{ backgroundColor: color }} 
            className={`deleteElementButton ${className}`} // Hier wird die Klasse hinzugefügt
        >
            <FontAwesomeIcon icon={faTrash} /> {/* Icon statt Label */}
        </button>
    );
};

export default DeleteElementButton;
