// src/components/PageBuilder/PageBuilder.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Section from './MainElements/Section/Section';
import ToolbarBuilder from '../Toolbar/ToolbarBuilder/ToolbarBuilder';
import './PageBuilder.css';

function PageBuilder() {
  const { id } = useParams(); // ID aus der URL erhalten
  const navigate = useNavigate();

  // Neuer Zustand für die gesamte Seite mit 'sections' als Schlüssel
  const [pageData, setPageData] = useState({
    sections: [
      { id: Date.now(), settings: {}, rows: [] }
    ]
  });

  const [viewMode, setViewMode] = useState('desktop'); // Ansichtszustand
  const [pageTitle, setPageTitle] = useState('Meine Seite');
  const [pageStatus, setPageStatus] = useState('draft'); // Zustand für den Seitenstatus

  // Seite laden, wenn eine ID vorhanden ist
  useEffect(() => {
    if (id) {
      loadPage(id);
    } else {
      // Neue Seite initialisieren
      setPageData({
        sections: [
          { id: Date.now(), settings: {}, rows: [] }
        ]
      });
      setPageTitle('Neue Seite');
    }
  }, [id]);

  // Funktionen zur Bearbeitung der Sections
  const addSection = (insertIndex) => {
    const newSection = { id: Date.now(), settings: {}, rows: [] };
    setPageData((prevData) => {
      const newSections = [...prevData.sections];
      if (insertIndex !== undefined) {
        newSections.splice(insertIndex, 0, newSection);
      } else {
        newSections.push(newSection);
      }
      return { ...prevData, sections: newSections };
    });
  };

  const deleteSection = (sectionId) => {
    setPageData((prevData) => {
      const newSections = prevData.sections.filter((section) => section.id !== sectionId);
      return { ...prevData, sections: newSections };
    });
  };

  const duplicateSection = (insertIndex, sectionData) => {
    const deepCopyWithNewIds = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => deepCopyWithNewIds(item));
      } else if (typeof obj === 'object' && obj !== null) {
        const newObj = { ...obj, id: Date.now() + Math.random() };
        for (const key in newObj) {
          if (key !== 'id') {
            newObj[key] = deepCopyWithNewIds(newObj[key]);
          }
        }
        return newObj;
      } else {
        return obj;
      }
    };

    const newSection = deepCopyWithNewIds(sectionData);

    setPageData((prevData) => {
      const newSections = [...prevData.sections];
      newSections.splice(insertIndex, 0, newSection);
      return { ...prevData, sections: newSections };
    });
  };

  const updateSectionRows = (sectionId, newRows) => {
    setPageData((prevData) => {
      const newSections = prevData.sections.map((section) =>
        section.id === sectionId ? { ...section, rows: newRows } : section
      );
      return { ...prevData, sections: newSections };
    });
  };

  const updateSectionSettings = (sectionId, newSettings) => {
    setPageData((prevData) => {
      const newSections = prevData.sections.map((section) =>
        section.id === sectionId ? { ...section, settings: newSettings } : section
      );
      return { ...prevData, sections: newSections };
    });
  };

  // Funktionen für die Ansichtsauswahl
  const activateDesktopView = () => {
    setViewMode('desktop');
  };

  const activateTabletView = () => {
    setViewMode('tablet');
  };

  const activateSmartphoneView = () => {
    setViewMode('smartphone');
  };

  // Slug-Generierung
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Nicht alphanumerische Zeichen durch '-' ersetzen
      .replace(/^-+|-+$/g, ''); // Führende und folgende '-' entfernen
  };

  // Funktion zum Speichern der Seite
  const savePage = () => {
    const slug = generateSlug(pageTitle);
    const pageDataToSave = {
      title: pageTitle,
      content: JSON.stringify(pageData),
      status: pageStatus,
      slug: slug,
    };

    const method = id ? 'PUT' : 'POST';
    const url = id ? `/api.php/pages/${id}` : '/api.php/pages';

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pageDataToSave),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!id && data.status === 'success' && data.page) {
          alert('Seite erfolgreich gespeichert! Seiten-ID: ' + data.page.id);
          navigate(`/page-builder/${data.page.id}`);
        } else if (data.status === 'success') {
          alert('Seite erfolgreich gespeichert!');
        } else if (data.error) {
          alert('Fehler beim Speichern der Seite: ' + data.error);
        } else {
          alert('Unbekannter Fehler beim Speichern der Seite.');
        }
      })
      .catch((error) => {
        console.error('Fehler beim Speichern der Seite:', error);
        alert('Fehler beim Speichern der Seite.');
      });
  };

  // Funktion zum Laden der Seite
  const loadPage = (pageId) => {
    fetch(`/api.php/pages/${pageId}`)
      .then((response) => response.json())
      .then((data) => {
        const loadedPageData = JSON.parse(data.content);
        setPageData(loadedPageData);
        setPageTitle(data.title);
        setPageStatus(data.status);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Seite:', error);
        alert('Fehler beim Laden der Seite.');
      });
  };

  return (
    <div className="PageBuilder">
      <ToolbarBuilder
        activateDesktopView={activateDesktopView}
        activateTabletView={activateTabletView}
        activateSmartphoneView={activateSmartphoneView}
        pageTitle={pageTitle}
        setPageTitle={setPageTitle}
      />

      {/* Status auswählen */}
      <div className="page-status-container">
        <label>
          Status:
          <select value={pageStatus} onChange={(e) => setPageStatus(e.target.value)}>
            <option value="draft">Entwurf</option>
            <option value="published">Veröffentlicht</option>
          </select>
        </label>
      </div>

      <div
        className={`sections-container 
          ${viewMode === 'desktop' ? 'desktop-view' : ''} 
          ${viewMode === 'tablet' ? 'tablet-view' : ''} 
          ${viewMode === 'smartphone' ? 'smartphone-view' : ''}`}
      >
        {pageData.sections.map((section, index) => (
          <Section
            key={section.id}
            sectionId={section.id}
            sectionIndex={index}
            addSection={(insertIndex) => addSection(insertIndex)}
            deleteSection={() => deleteSection(section.id)}
            duplicateSection={(insertIndex, data) => duplicateSection(insertIndex, data)}
            settings={section.settings}
            rows={section.rows}
            updateSectionRows={(newRows) => updateSectionRows(section.id, newRows)}
            updateSectionSettings={(newSettings) =>
              updateSectionSettings(section.id, newSettings)
            }
          />
        ))}
      </div>

      {/* Speicherbutton hinzufügen */}
      <button className="save-button" onClick={savePage}>
        Speichern
      </button>
    </div>
  );
}

export default PageBuilder;
