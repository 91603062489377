// src/components/Common/IconButton/IconButton.js
import React from 'react';
import './IconButton.css';

function IconButton({ icon, onClick, to, ariaLabel, title, className, style }) {
  const ButtonContent = (
    <button
      className={`icon-button ${className}`}
      onClick={onClick}
      aria-label={ariaLabel}
      title={title}
      style={style}
    >
      {icon}
    </button>
  );

  if (to) {
    return (
      <a href={to} className="icon-button-link">
        {ButtonContent}
      </a>
    );
  }

  return ButtonContent;
}

export default IconButton;
