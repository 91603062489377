// BackgroundSettings.js
import React from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const BackgroundSettings = ({ settings, onChange }) => {
    return (
        <AccordionWrapper title="Hintergrund">
            <div className="settingCategory background-settings">
                <p className="settingCategoryDescription">Hintergrundeinstellungen für das gesamte Element.</p>
                <input 
                    type="color" 
                    value={settings.backgroundColor || '#ffffff'} 
                    onChange={(e) => onChange({ ...settings, backgroundColor: e.target.value })}
                />
                <input 
                    type="text" 
                    value={settings.backgroundImage || ''} 
                    onChange={(e) => onChange({ ...settings, backgroundImage: e.target.value })}
                    placeholder="Bild-URL eingeben"
                />
            </div>
        </AccordionWrapper>
    );
};

export default BackgroundSettings;
