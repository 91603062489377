// src/components/AdminDashboard/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import LeftSidebar from './LeftSidebar/LeftSidebar';
import RightSidebar from './RightSidebar/RightSidebar';
import Content from './Content/Content';
import ToolbarAdmin from '../Toolbar/ToolbarAdmin/ToolbarAdmin'; 
import IconButton from './IconButton/IconButton'; // Stelle sicher, dass der Pfad korrekt ist
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCog, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './AdminDashboard.css';

function AdminDashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    if (windowWidth < 1000) {
      setIsSidebarOpen(false);
    }
  };

  // Definiere die IconButtons für die RightSidebar
  const rightSidebarButtons = [
    {
      icon: <FontAwesomeIcon icon={faBell} />,
      onClick: () => alert('Benachrichtigungen'),
      ariaLabel: 'Benachrichtigungen',
      title: 'Benachrichtigungen',
      className: 'notification-button',
    },
    {
      icon: <FontAwesomeIcon icon={faCog} />,
      onClick: () => alert('Einstellungen'),
      ariaLabel: 'Einstellungen',
      title: 'Einstellungen',
      className: 'settings-button',
    },
    {
      icon: <FontAwesomeIcon icon={faUser} />,
      onClick: () => alert('Profil'),
      ariaLabel: 'Profil',
      title: 'Profil',
      className: 'profile-button',
    },
    {
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      onClick: () => alert('Abmelden'),
      ariaLabel: 'Abmelden',
      title: 'Abmelden',
      className: 'logout-button',
    },
  ];

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <ToolbarAdmin toggleSidebar={toggleSidebar} />
      <div className="main">
        <LeftSidebar isOpen={isSidebarOpen} onLinkClick={closeSidebar} />
        <Content />
        <RightSidebar isOpen={isSidebarOpen} buttons={rightSidebarButtons} />
      </div>
    </div>
  );
}

export default AdminDashboard;
