
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faBars, faPalette, faImages } from '@fortawesome/free-solid-svg-icons';
import './ToolbarAdminTitle.css';

function ToolbarAdminTitle() {
  const location = useLocation();

  // Funktion zur Bestimmung des Seitentitels basierend auf der Route
  const getTitle = () => {
    const path = location.pathname;

    if (path.startsWith('/admin/pages')) {
      return 'Inhalte verwalten';
    } else if (path.startsWith('/admin/navigation')) {
      return 'Navigation bearbeiten';
    } else if (path.startsWith('/admin/templates')) {
      return 'Vorlagenbibliothek';
    } else if (path.startsWith('/admin/media')) {
      return 'Media Library';
    } else if (path.startsWith('/admin/page-builder')) {
      return 'Page Builder';
    } else {
      return 'Willkommen im Admin-Bereich';
    }
  };

  return (
    <div className="toolbar-admin-title">  
      <h1>{getTitle()}</h1>  
    </div>
  );
}

export default ToolbarAdminTitle;
