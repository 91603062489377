// src/components/Start/Start.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Start.css'; // Optional für Styling

function Start() {
  return (
    <div className="start-page">
      <h1>Willkommen im Admin-Bereich</h1>
      <Link to="/admin">Zum Admin-Bereich</Link>
    </div>
  );
}

export default Start;
