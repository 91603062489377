// src/components/PageBuilder/MainElements/Row/Row.js

import React, { useState, useEffect } from 'react';
import Column from '../Column/Column';
import AddButton from '../../Buttons/AddElementButton/AddElementButton';
import SettingsModal from '../../Modals/SettingsModal/SettingsModal';
import ConfirmModal from '../../Modals/ConfirmModal/ConfirmModal';
import LinkSettings from '../../Modals/SettingsModal/Settings/LinkSettings/LinkSettings';
import BackgroundSettings from '../../Modals/SettingsModal/Settings/BackgroundSettings/BackgroundSettings';
import LayoutSettings from '../../Modals/SettingsModal/Settings/LayoutSettings/LayoutSettings';
import SpacingSettings from '../../Modals/SettingsModal/Settings/SpacingSettings/SpacingSettings';
import BorderSettings from '../../Modals/SettingsModal/Settings/BorderSettings/BorderSettings';
import CSSIdClassSettings from '../../Modals/SettingsModal/Settings/CSSIdClassSettings/CSSIdClassSettings';
import CustomCSSSettings from '../../Modals/SettingsModal/Settings/CustomCSSSettings/CustomCSSSettings';
import ElementToolbox from '../../ElementToolbox/ElementToolbox';
import './Row.css';

const Row = ({
  rowId,
  rowIndex,
  sectionId,
  layout,
  addRowAt,
  index,
  deleteRow,
  duplicateRow,
  settings: initialSettings,
  columns: initialColumns,
  updateRowSettings,
  updateRowColumns,
}) => {
  const [settings, setSettings] = useState(initialSettings || {});
  const [columns, setColumns] = useState(initialColumns || []);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [prevSettings, setPrevSettings] = useState({});
  const [prevColumns, setPrevColumns] = useState([]);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [pendingLayout, setPendingLayout] = useState(null);

  useEffect(() => {
    setSettings(initialSettings || {});
  }, [initialSettings]);

  useEffect(() => {
    setColumns(initialColumns || []);
  }, [initialColumns]);

  // Funktionen für das Settings Modal
  const openSettings = () => {
    setPrevSettings({ ...settings });
    setPrevColumns(columns.map((col) => ({ ...col })));
    setSettingsOpen(true);
  };

  const closeSettings = () => {
    setSettings({ ...prevSettings });
    setColumns(prevColumns);
    updateRowSettings(prevSettings);
    updateRowColumns(prevColumns);
    setSettingsOpen(false);
  };

  const saveSettings = () => {
    setSettingsOpen(false);
    if (updateRowSettings) {
      updateRowSettings(settings);
    }
    if (updateRowColumns) {
      updateRowColumns(columns);
    }
  };

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  // Funktionen zum Aktualisieren der Columns
  const updateColumnModules = (colId, newModules) => {
    const updatedColumns = columns.map((col) =>
      col.id === colId ? { ...col, modules: newModules } : col
    );
    setColumns(updatedColumns);
  };

  const updateColumnSettingsFunc = (colId, newSettings) => {
    const updatedColumns = columns.map((col) =>
      col.id === colId ? { ...col, settings: newSettings } : col
    );
    setColumns(updatedColumns);
  };

  const updateColumnWidth = (colId, newWidth) => {
    const updatedColumns = columns.map((col) =>
      col.id === colId ? { ...col, width: newWidth } : col
    );
    setColumns(updatedColumns);
  };

  // Funktion zum Löschen einer Column
  const deleteColumn = (colId) => {
    const updatedColumns = columns.filter((col) => col.id !== colId);

    if (updatedColumns.length === 0) {
      deleteRow();
    } else {
      setColumns(updatedColumns);
    }
  };

  // Funktion zum Verarbeiten der Layoutauswahl
  const handleLayoutSelect = (newLayout) => {
    const newWidths = newLayout.split(' ');
    if (newWidths.length === columns.length) {
      const updatedColumns = columns.map((col, idx) => ({
        ...col,
        width: newWidths[idx],
      }));
      setColumns(updatedColumns);
    } else if (newWidths.length > columns.length) {
      const existingColumns = columns.map((col, idx) => ({
        ...col,
        width: newWidths[idx],
      }));
      const newColumns = newWidths.slice(columns.length).map((width) => ({
        id: Date.now() + Math.random(),
        width,
        settings: {},
        modules: [],
      }));
      const allColumns = [...existingColumns, ...newColumns];
      setColumns(allColumns);
    } else {
      setPendingLayout(newWidths);
      setConfirmModalOpen(true);
    }
  };

  // Funktionen für ConfirmModal
  const handleConfirm = () => {
    const newColumns = pendingLayout.map((width, idx) =>
      columns[idx] || { id: Date.now() + Math.random(), width, settings: {}, modules: [] }
    );
    setColumns(newColumns);
    setPendingLayout(null);
    setConfirmModalOpen(false);
  };

  const handleCancel = () => {
    setPendingLayout(null);
    setConfirmModalOpen(false);
  };

  // Funktion zum Duplizieren der Row
  const handleDuplicate = () => {
    const deepCopyWithNewIds = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => deepCopyWithNewIds(item));
      } else if (typeof obj === 'object' && obj !== null) {
        const newObj = { ...obj, id: Date.now() + Math.random() };
        for (const key in newObj) {
          if (key !== 'id') {
            newObj[key] = deepCopyWithNewIds(newObj[key]);
          }
        }
        return newObj;
      } else {
        return obj;
      }
    };

    const rowData = {
      layout: columns.map((col) => col.width).join(' '),
      settings: { ...settings },
      columns: columns.map((col) => ({
        ...col,
        modules: col.modules.map((mod) => ({ ...mod })),
      })),
    };

    const newRowData = deepCopyWithNewIds(rowData);
    duplicateRow(newRowData);
  };

  // Definition der Unterkomponenten für die Tabs im Settings Modal
  const settingsComponents = {
    Inhalt: (
      <>
        <LinkSettings settings={settings} onChange={handleSettingsChange} />
        <BackgroundSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Design: (
      <>
        <LayoutSettings onSelectLayout={handleLayoutSelect} />
        <SpacingSettings settings={settings} onChange={handleSettingsChange} />
        <BorderSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Erweitert: (
      <>
        <CSSIdClassSettings settings={settings} onChange={handleSettingsChange} />
        <CustomCSSSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
  };

  useEffect(() => {
    if (updateRowColumns) {
      updateRowColumns(columns);
    }
  }, [columns, updateRowColumns]);

  return (
    <div
      className={`row ${settings.className || ''}`}
      id={settings.id || undefined}
      style={{
        display: 'grid',
        gridTemplateColumns: columns.map((col) => col.width).join(' '),
        backgroundColor: settings.backgroundColor,
        backgroundImage: settings.backgroundImage
          ? `url(${settings.backgroundImage})`
          : undefined,
        marginTop: settings.marginTop,
        marginBottom: settings.marginBottom,
        marginLeft: settings.marginLeft,
        marginRight: settings.marginRight,
        paddingTop: settings.paddingTop,
        paddingBottom: settings.paddingBottom,
        paddingLeft: settings.paddingLeft,
        paddingRight: settings.paddingRight,
        borderWidth: settings.borderWidth,
        borderStyle: settings.borderStyle,
        borderColor: settings.borderColor,
        borderRadius: settings.borderRadius,
        boxShadow: settings.boxShadow,
      }}
    >
      {/* ElementToolbox */}
      <ElementToolbox
        onOpenSettings={openSettings}
        onDuplicate={handleDuplicate}
        onDelete={deleteRow}
        color="#FF9400"
        className="row-toolbox"
      />

      {/* Darstellung der Columns */}
      {columns.map((col, idx) => (
        <Column
          key={col.id || idx}
          columnId={col.id}
          columnIndex={idx}
          width={col.width}
          settings={col.settings}
          modules={col.modules}
          deleteColumn={() => deleteColumn(col.id)}
          updateColumnWidth={(newWidth) => updateColumnWidth(col.id, newWidth)}
          updateColumnSettings={(newSettings) => updateColumnSettingsFunc(col.id, newSettings)}
          updateColumnModules={(newModules) => updateColumnModules(col.id, newModules)}
        />
      ))}

      {/* Add Row Button */}
      <AddButton
        onClick={() => addRowAt(index + 1)}
        label="Add Row"
        color="#FF9400"
        className="bottomAddElementButton bottomAddRowButton"
      />

      {/* SettingsModal und ConfirmModal */}
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={closeSettings}
        onSave={saveSettings}
        settingsComponents={settingsComponents}
      />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        message="Das neue Layout hat weniger Spalten als das aktuelle. Inhalte in entfernten Spalten gehen verloren. Möchten Sie fortfahren?"
      />
    </div>
  );
};

export default Row;
