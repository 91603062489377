// src/components/AdminDashboard/MediaLibrary/MediaLibrary.js
import React from 'react';
import { Link } from 'react-router-dom';

function MediaLibrary() {
  // Beispielhafte Daten

  return (
    <div>
      <h2>MediaLibrary</h2>
    </div>
  );
}

export default MediaLibrary;
