import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './AccordionWrapper.css';

const AccordionWrapper = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-wrapper">
            <div className="accordion-header" onClick={toggleAccordion}>
                <h3>{title}</h3>
                <span>
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                </span>
            </div>
            <div
                className="accordion-body"
                ref={contentRef}
                style={{
                    maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0',
                    overflow: 'hidden',
                }}
            >
                <div className="accordion-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccordionWrapper;
