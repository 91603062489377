// ToolbarAdmin.js
import React from "react";
import Toolbar from "../Toolbar";
import "./ToolbarAdmin.css";
import ToolbarAdminTitle from "./ToolbarAdminTitle/ToolbarAdminTitle";
import logo from "../../../assets/images/NP5.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const ToolbarAdmin = ({ toggleSidebar }) => {
  
  const handleHamburgerClick = () => {
    // Haptisches Feedback auslösen
    if (navigator.vibrate) { // Überprüfen, ob die Vibration API unterstützt wird
      navigator.vibrate([30]); // Gerät vibrieren lassen für 30 Millisekunden
    }
    // Sidebar umschalten
    toggleSidebar();
  };

   return (
     <Toolbar logo={<img src={logo} alt="Logo" />}>
       <div className="toolbar-admin-subgrid">
         <ToolbarAdminTitle />
         <div></div>
         <button className="hamburger-button" onClick={handleHamburgerClick}>
           <FontAwesomeIcon icon={faBars} />
         </button>
       </div>
     </Toolbar>
   );
 };
 
 export default ToolbarAdmin;
