// SettingsModal.js
import React, { useState } from 'react';
import Modal from '../../../Modal/Modal';

const SettingsModal = ({ isOpen, onClose, onSave, settingsComponents }) => {
    const [activeTab, setActiveTab] = useState('Inhalt');

    const tabs = ['Inhalt', 'Design', 'Erweitert'];

    const tabsContent = tabs.map(tab => (
        <button
            key={tab}
            className={`tab-button ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
        >
            {tab}
        </button>
    ));

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Element Einstellungen"
            draggable={true}
            tabs={tabsContent}
        >
            <div className="modal-body">
                {settingsComponents[activeTab]}
            </div>
            <div className="modal-footer">
                <button className="button-save" onClick={onSave}>Speichern</button>
                <button className="button-cancel" onClick={onClose}>Abbrechen</button>
            </div>
        </Modal>
    );
};

export default SettingsModal;
