// LayoutModal.js
import React from 'react';
import Modal from '../../../Modal/Modal';
import LayoutSettings from '../SettingsModal/Settings/LayoutSettings/LayoutSettings';

const LayoutModal = ({ isOpen, onClose, onSelectLayout }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Wähle ein Layout"
            draggable={true}
        >
            <div className="modal-body">
                <LayoutSettings onSelectLayout={onSelectLayout} />
            </div>
            <div className="modal-footer">
                <button className="button-cancel" onClick={onClose}>Abbrechen</button>
            </div>
        </Modal>
    );
};

export default LayoutModal;
