// SwitchDevViewButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'; // Icon für Ansicht ein-/ausblenden
import './SwitchDevViewButton.css';

const SwitchDevViewButton = ({ onClick, color, className }) => {
    return (
        <button
            onClick={onClick}
            style={{ backgroundColor: color }}
            className={`switchDevViewButton ${className || ''}`}
        >
            <FontAwesomeIcon icon={faEye} />
        </button>
    );
};

export default SwitchDevViewButton;
