// Toolbar.js
import React from "react";
import "./Toolbar.css";

const Toolbar = ({ children, logo }) => {
  return (
    <div className="toolbar">
      <div className="toolbar-logo">
        {logo}
      </div>
      <div className="toolbar-content">
        {children}
      </div>
    </div>
  );
};

export default Toolbar;
