// src/components/Toolbar/ToolbarBuilder/PageTitle/PageTitle.js
import React from 'react';
import "./PageTitle.css";

const PageTitle = ({ pageTitle, setPageTitle }) => {
    return (
        <div className="pagetitle">
            <input
                type="text"
                placeholder="Layout-Name eingeben"
                value={pageTitle}
                onChange={(e) => setPageTitle(e.target.value)}
            />
        </div>
    );
};

export default PageTitle;
