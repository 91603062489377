// ColumnWidthSettings.js
import React, { useState } from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const ColumnWidthSettings = ({ width, onChange }) => {
    const [inputValue, setInputValue] = useState(width);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        onChange(e.target.value); // Echtzeit-Aktualisierung der Spaltengröße
    };

    return (
        <AccordionWrapper title="Spaltengröße">
            <div className="settingCategory column-width-settings">
                <p className="settingCategoryDescription">Die Größe der Spalte im Layout anpassen.</p>
                <div>
                    <label>Spaltengröße:</label>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="z.B. 1fr, 2fr"
                    />
                </div>
            </div>
        </AccordionWrapper>
    );
};

export default ColumnWidthSettings;
