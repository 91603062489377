// SelectModuleModal
import React from 'react';
import Modal from '../../../Modal/Modal';
import './SelectModuleModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faCode, faImage } from '@fortawesome/free-solid-svg-icons'; // Beispiel-Icons

const SelectModuleModal = ({ isOpen, onClose, onSelectModule }) => {
    const modules = [
        { name: 'TestModule', icon: faCube },
        { name: 'CodeModul', icon: faCode },
        { name: 'ImageModul', icon: faImage },
    ];

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Wähle ein Modul"
            draggable={true}
        >
            <div className="modal-body">
                <div className="module-grid">
                    {modules.map((module) => (
                        <button
                            key={module.name}
                            className="module-button"
                            onClick={() => onSelectModule(module.name)}
                        >
                            <FontAwesomeIcon className="module-button-icon" icon={module.icon} size="2x" />
                            <span className="module-name">{module.name}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div className="modal-footer">
                <button className="button-cancel" onClick={onClose}>Abbrechen</button>
            </div>
        </Modal>
    );
};

export default SelectModuleModal;
