// src/components/AdminDashboard/NavigationEditor/NavigationEditor.js
import React from 'react';
import { Link } from 'react-router-dom';

function NavigationEditor() {
  // Beispielhafte Daten

  return (
    <div>
      <h2>NavigationEditor</h2>
    </div>
  );
}

export default NavigationEditor;
