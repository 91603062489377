// src/components/AdminDashboard/PageList/PageList.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../Table/Table';
import IconButton from '../IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faClone, faEye, faCheckCircle, faTimesCircle, faHome } from '@fortawesome/free-solid-svg-icons';
import './PageList.css';

function PageList() {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true); // Ladezustand
  const [error, setError] = useState(null); // Fehlerzustand
  const navigate = useNavigate();


  useEffect(() => {
    fetch('/api.php/pages')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        return response.json();
      })
      .then((data) => {
        setPages(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Seiten:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const columns = [
    { label: 'ID', field: 'id' },
    { label: 'Titel', field: 'title' },
    {
      label: 'Status',
      field: 'status',
      render: (status, item) => (
        <div className="status-cell">
          {/* Bedingtes Rendering des faHome-Icons, wenn die Seite die Startseite ist */}
          {item.is_homepage ? (
            <FontAwesomeIcon icon={faHome} className="homepage-icon" />
          ) : null}
          <span
            className={`status-dot ${status === 'published' ? 'published' : 'draft'}`}
            aria-label={status === 'published' ? 'Veröffentlicht' : 'Entwurf'}
          ></span>
          <span className="status-text">
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </span>
        </div>
      ),
    },
    {
      label: 'Erstellt am',
      field: 'created_at',
      render: (created_at) => formatDateTime(created_at),
    },
    {
      label: 'Zuletzt bearbeitet',
      field: 'updated_at',
      render: (updated_at) => formatDateTime(updated_at),
    },
  ];

  // Hilfsfunktion zur Formatierung von Datum und Uhrzeit
  const formatDateTime = (datetimeStr) => {
    const date = new Date(datetimeStr);

    // Prüfen, ob das Datum gültig ist
    if (isNaN(date.getTime())) {
      return <span>Ungültiges Datum</span>;
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind 0-indexiert
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return (
      <div>
        {`${day}.${month}.${year}`}<br />
        {`${hours}:${minutes}:${seconds}`}
      </div>
    );
  };


  const actions = [
    {
      render: (item) => (
        <IconButton
          icon={<FontAwesomeIcon icon={faEdit} />}
          ariaLabel={`Seite ${item.title} bearbeiten`}
          title="Bearbeiten"
          onClick={() => navigate(`/admin/page-builder/${item.id}`)}
          className="edit-button"
        />
      ),
    },
    {
      render: (item) => (
        <IconButton
          icon={<FontAwesomeIcon icon={faClone} />}
          ariaLabel={`Seite ${item.title} duplizieren`}
          title="Duplizieren"
          onClick={() => handleDuplicate(item.id)}
          className="duplicate-button"
        />
      ),
    },
    {
      render: (item) => (
        <IconButton
          icon={<FontAwesomeIcon icon={item.status === 'published' ? faTimesCircle : faCheckCircle} />}
          ariaLabel={item.status === 'published' ? `Seite ${item.title} unveröffentlichen` : `Seite ${item.title} veröffentlichen`}
          title={item.status === 'published' ? 'Unveröffentlichen' : 'Veröffentlichen'}
          onClick={() => togglePageStatus(item)}
          className={`status-icon-button ${item.status === 'published' ? 'unpublish' : 'publish'}`}
        />
      ),
    },
    {
      render: (item) => (
        <IconButton
          icon={<FontAwesomeIcon icon={faHome} />}
          ariaLabel={item.is_homepage ? `Seite ${item.title} ist bereits die Startseite` : `Seite ${item.title} als Startseite festlegen`}
          title={item.is_homepage ? 'Startseite' : 'Als Startseite festlegen'}
          onClick={() => setAsHomepage(item.id)}
          className="homepage-button"
          disabled={item.is_homepage}
        />
      ),
    },
    // Neuer Button zum Anzeigen der veröffentlichten Seite
    {
      render: (item) => (
        <IconButton
          icon={<FontAwesomeIcon icon={faEye} />}
          ariaLabel={`Seite ${item.title} anzeigen`}
          title="Anzeigen"
          onClick={() => window.open(`http://localhost/website-builder/${item.slug}`, '_blank')}
          className="view-button"
          disabled={item.status !== 'published'} // Deaktivieren, wenn die Seite nicht veröffentlicht ist
        />
      ),
    },
    {
      render: (item) => (
        <IconButton
          icon={<FontAwesomeIcon icon={faTrash} />}
          ariaLabel={`Seite ${item.title} löschen`}
          title="Löschen"
          onClick={() => handleDelete(item.id)}
          className="delete-button"
        />
      ),
    },
  ];


  const togglePageStatus = (page) => {
    const newStatus = page.status === 'published' ? 'draft' : 'published';

    fetch(`/api.php/pages/${page.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: newStatus }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Fehler beim Aktualisieren des Seitenstatus');
        }
        // Seitenliste aktualisieren
        setPages((prevPages) =>
          prevPages.map((p) => (p.id === page.id ? { ...p, status: newStatus } : p))
        );
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren des Seitenstatus:', error);
        alert('Fehler beim Aktualisieren des Seitenstatus.');
      });
  };

  const setAsHomepage = (id) => {
    fetch(`/api.php/set-homepage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Fehler beim Festlegen der Startseite');
        }
        // Seitenliste aktualisieren
        setPages((prevPages) =>
          prevPages.map((page) => ({
            ...page,
            is_homepage: page.id === id,
          }))
        );
      })
      .catch((error) => {
        console.error('Fehler beim Festlegen der Startseite:', error);
        alert('Fehler beim Festlegen der Startseite.');
      });
  };

  const handleDelete = (id) => {
    if (window.confirm('Möchten Sie diese Seite wirklich löschen?')) {
      fetch(`/api.php/pages/${id}`, {
        method: 'DELETE',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Fehler beim Löschen der Seite');
          }
          // Seite erfolgreich gelöscht, aktualisiere die Seitenliste
          setPages((prevPages) => prevPages.filter((page) => page.id !== id));
        })
        .catch((error) => {
          console.error('Fehler beim Löschen der Seite:', error);
          alert('Fehler beim Löschen der Seite.');
        });
    }
  };

  const handleDuplicate = (id) => {
    // Zuerst die Originalseite abrufen
    fetch(`/api.php/pages/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Fehler beim Abrufen der Seite zum Duplizieren');
        }
        return response.json();
      })
      .then((originalPage) => {
        // Neue Seite erstellen mit den Daten der Originalseite
        const duplicatedPageData = {
          title: originalPage.title + ' (Kopie)',
          content: originalPage.content,
          status: 'draft', // Optional: Setze den Status auf 'draft' für duplizierte Seiten
          // slug wird vom Backend generiert
        };

        return fetch('/api.php/pages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(duplicatedPageData),
        });
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Fehler beim Erstellen der duplizierten Seite');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success' && data.page) {
          // Neue Seite zur Seitenliste hinzufügen
          setPages((prevPages) => [...prevPages, data.page]);
          alert('Seite erfolgreich dupliziert!');
        } else {
          throw new Error('Unerwartete Antwort vom Server');
        }
      })
      .catch((error) => {
        console.error('Fehler beim Duplizieren der Seite:', error);
        alert('Fehler beim Duplizieren der Seite.');
      });
  };

  if (loading) {
    return (
      <div className="page-list">
        <p>Seiten werden geladen...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-list">
        <p>Fehler beim Laden der Seiten: {error.message}</p>
      </div>
    );
  }

  return (
    <div className="page-list">
      <div className="page-list-header">
        <h2>Seiten</h2>
        <button
          className="create-button"
          onClick={() => navigate('/admin/page-builder')}
        >
          Neue Seite erstellen
        </button>
      </div>
      {pages.length === 0 ? (
        <p>Keine Seiten gefunden.</p>
      ) : (
        <Table
          columns={columns}
          data={pages}
          actions={actions}
          keyField="id"
        />
      )}
    </div>
  );
}

export default PageList;
