// SpacingSettings.js
import React from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const SpacingSettings = ({ settings, onChange }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({ ...settings, [name]: value });
    };

    return (
        <AccordionWrapper title="Spacing">
            <div className="settingCategory spacing-settings">
                <p className="settingCategoryDescription">Abstände und Zwischenräume für das gesamte Element definieren.</p>
                <div>
                    <label>Margin Top:</label>
                    <input
                        type="text"
                        name="marginTop"
                        value={settings.marginTop || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
                <div>
                    <label>Margin Bottom:</label>
                    <input
                        type="text"
                        name="marginBottom"
                        value={settings.marginBottom || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
                <div>
                    <label>Margin Left:</label>
                    <input
                        type="text"
                        name="marginLeft"
                        value={settings.marginLeft || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
                <div>
                    <label>Margin Right:</label>
                    <input
                        type="text"
                        name="marginRight"
                        value={settings.marginRight || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
                <div>
                    <label>Padding Top:</label>
                    <input
                        type="text"
                        name="paddingTop"
                        value={settings.paddingTop || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
                <div>
                    <label>Padding Bottom:</label>
                    <input
                        type="text"
                        name="paddingBottom"
                        value={settings.paddingBottom || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
                <div>
                    <label>Padding Left:</label>
                    <input
                        type="text"
                        name="paddingLeft"
                        value={settings.paddingLeft || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
                <div>
                    <label>Padding Right:</label>
                    <input
                        type="text"
                        name="paddingRight"
                        value={settings.paddingRight || ''}
                        onChange={handleChange}
                        placeholder="z.B. 10px"
                    />
                </div>
            </div>
        </AccordionWrapper>
    );  
};

export default SpacingSettings;
