// TabletViewButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons'; // Tablet-Icon
import './TabletViewButton.css';

const TabletViewButton = ({ onClick, color, className }) => {
    return (
        <button
            onClick={onClick}
            style={{ backgroundColor: color }}
            className={`tabletViewButton ${className || ''}`}
        >
            <FontAwesomeIcon icon={faTabletAlt} />
        </button>
    );
};

export default TabletViewButton;
