// ConfirmModal.js
import React from 'react';
import Modal from '../../../Modal/Modal';

const ConfirmModal = ({ isOpen, onClose, onConfirm, message }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Bestätigung"
            draggable={true}
        >
            <div className="modal-body confirm-modal-body">
                <p>{message}</p>
            </div>
            <div className="modal-footer">
                <button className="button-confirm" onClick={onConfirm}>Ja</button>
                <button className="button-cancel" onClick={onClose}>Nein</button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
