// src/components/AdminDashboard/TemplateLibrary/TemplateLibrary.js
import React from 'react';
import { Link } from 'react-router-dom';

function TemplateLibrary() {
  // Beispielhafte Daten

  return (
    <div>
      <h2>TemplateLibrary</h2>
    </div>
  );
}

export default TemplateLibrary;
