// CSSIdClassSettings.js
import React from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const CSSIdClassSettings = ({ settings, onChange }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({ ...settings, [name]: value });
    };

    return (
        <AccordionWrapper title="CSS-ID und Klassen">
            <div className="settingCategory cssidclass-settings">
                <p className="settingCategoryDescription">Eine CSS-ID oder CSS-Klasse(n) für das Element hinzufügen.</p>
                <div>
                    <label>ID:</label>
                    <input
                        type="text"
                        name="id"
                        value={settings.id || ''}
                        onChange={handleChange}
                        placeholder="ID eingeben"
                    />
                </div>
                <div>
                    <label>Klassen:</label>
                    <input
                        type="text"
                        name="className"
                        value={settings.className || ''}
                        onChange={handleChange}
                        placeholder="Klassen eingeben"
                    />
                </div>
            </div>
        </AccordionWrapper>
    );
};

export default CSSIdClassSettings;
