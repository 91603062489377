// LayoutSettings.js
import React from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const LayoutSettings = ({ onSelectLayout }) => {
    const layouts = {
        "1-Spalten-Layouts": ["1fr"],
        "2-Spalten-Layouts": ["1fr 1fr", "1fr 2fr", "2fr 1fr", "1fr 3fr", "3fr 1fr", "1fr 4fr", "4fr 1fr", "1fr 5fr"],
        "3-Spalten-Layouts": ["1fr 1fr 1fr", "1fr 1fr 2fr", "1fr 2fr 1fr", "2fr 1fr 1fr", "2fr 2fr 1fr", "1fr 2fr 2fr", "1fr 3fr 1fr", "1fr 1fr 3fr"],
        "4-Spalten-Layouts": ["1fr 1fr 1fr 1fr", "1fr 1fr 1fr 3fr", "3fr 1fr 1fr 1fr", "1fr 2fr 2fr 1fr"],
    };

    return (
        <AccordionWrapper title="Layout">
            <div className="settingCategory layout-settings">
                <p className="settingCategoryDescription">Anzahl Spalten und deren Größenverhältnisse definieren.</p>
                {Object.keys(layouts).map((category) => (
                    <div key={category}>
                        <h4 className="layoutSettingCategoryTitle">{category}</h4>
                        <div className="layout-grid">
                            {layouts[category].map((layout) => (
                                <button
                                    key={layout}
                                    className="layout-button"
                                    onClick={() => onSelectLayout(layout)}
                                >
                                    {/* Vorschau der Spalten */}
                                    <div className="layout-preview" style={{ display: 'grid', gridTemplateColumns: layout }}>
                                        {layout.split(" ").map((_, index) => (
                                            <div key={index} className="layout-preview-bar" />
                                        ))}
                                    </div>
                                    {/* Text unter der Vorschau */}
                                    <span className="layout-text">{layout}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </AccordionWrapper>
    );
};

export default LayoutSettings;