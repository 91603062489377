// ElementToolbox.js
import React from 'react';
import OpenElementSettingsButton from '../Buttons/OpenElementSettingsButton/OpenElementSettingsButton';
import DuplicateElementButton from '../Buttons/DuplicateElementButton/DuplicateElementButton';
import DeleteElementButton from '../Buttons/DeleteElementButton/DeleteElementButton';
import DragDropElementButton from '../Buttons/DragDropElementButton/DragDropElementButton';
import './ElementToolbox.css';

const ElementToolbox = ({
  onOpenSettings,
  onDuplicate,
  onDelete,
  showDuplicate = true,
  color,
  className,
  dragRef, // Neu: Ref für Dragging
}) => {
  return (
    <div className={`element-toolbox ${className || ''}`}>
      <OpenElementSettingsButton
        onClick={onOpenSettings}
        color={color}
        className="toolbox-button"
      />
      {showDuplicate && (
        <DuplicateElementButton
          onClick={onDuplicate}
          color={color}
          className="toolbox-button"
        />
      )}
      <DragDropElementButton
        color={color}
        className="toolbox-button"
        dragRef={dragRef} // Übergib das Drag-Ref
      />
      <DeleteElementButton
        onClick={onDelete}
        color={color}
        className="toolbox-button"
      />
    </div>
  );
};

export default ElementToolbox;
