// src/components/Toolbar/ToolbarBuilder/ToolbarBuilder.js
import React from "react";
import Toolbar from "../Toolbar";
import "./ToolbarBuilder.css";
import PageTitle from "./PageTitle/PageTitle";
import ToolbarBuilderTools from "./ToolbarBuilderTools/ToolbarBuilderTools";
import ToolbarBuilderResponsiveViews from "./ToolbarBuilderResponsiveViews/ToolbarBuilderResponsiveViews";
import logo from "../../../assets/images/NP5.png";

const ToolbarBuilder = ({ 
  activateDesktopView, 
  activateTabletView, 
  activateSmartphoneView,
  pageTitle,
  setPageTitle
}) => {
  return (
    <Toolbar logo={<img src={logo} alt="Logo" />}>
      <div className="toolbar-builder-subgrid">
        <PageTitle pageTitle={pageTitle} setPageTitle={setPageTitle} />
        <ToolbarBuilderTools />
        <ToolbarBuilderResponsiveViews 
          activateDesktopView={activateDesktopView}
          activateTabletView={activateTabletView}
          activateSmartphoneView={activateSmartphoneView} 
        /> 
      </div>
    </Toolbar>
  );
};

export default ToolbarBuilder;
