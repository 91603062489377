// src/components/Common/Table/Table.js
import React from 'react';
import './Table.css';

function Table({ columns, data, actions, keyField }) {
  return (
    <table className="common-table">
      <thead>
        <tr>
          {columns.map((col) => (
            <th key={col.field}>{col.label}</th>
          ))}
          {actions && actions.length > 0 && <th>Aktionen</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item[keyField]}>
            {columns.map((col) => (
              <td key={col.field}>
                {col.render ? col.render(item[col.field], item) : item[col.field]}
              </td>
            ))}
            {actions && actions.length > 0 && (
              <td className="table-actions">
                {actions.map((action, index) => (
                  <React.Fragment key={index}>
                    {action.render(item)}
                  </React.Fragment>
                ))}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
