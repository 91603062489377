// src/components/PageBuilder/MainElements/Column/Column.js

import React, { useState, useEffect } from 'react';
import AddElementButton from '../../Buttons/AddElementButton/AddElementButton';
import SelectModuleModal from '../../Modals/SelectModuleModal/SelectModuleModal';
import TestModule from '../Module/Modules/TestModule/TestModule';
import SettingsModal from '../../Modals/SettingsModal/SettingsModal';
import LinkSettings from '../../Modals/SettingsModal/Settings/LinkSettings/LinkSettings';
import BackgroundSettings from '../../Modals/SettingsModal/Settings/BackgroundSettings/BackgroundSettings';
import SpacingSettings from '../../Modals/SettingsModal/Settings/SpacingSettings/SpacingSettings';
import BorderSettings from '../../Modals/SettingsModal/Settings/BorderSettings/BorderSettings';
import CSSIdClassSettings from '../../Modals/SettingsModal/Settings/CSSIdClassSettings/CSSIdClassSettings';
import CustomCSSSettings from '../../Modals/SettingsModal/Settings/CustomCSSSettings/CustomCSSSettings';
import ColumnWidthSettings from '../../Modals/SettingsModal/Settings/ColumnWidthSettings/ColumnWidthSettings';
import ElementToolbox from '../../ElementToolbox/ElementToolbox';
import './Column.css';

const Column = ({
  columnId,
  columnIndex,
  width,
  deleteColumn,
  updateColumnWidth,
  settings: initialSettings,
  updateColumnSettings,
  modules: initialModules,
  updateColumnModules,
}) => {
  const [settings, setSettings] = useState(initialSettings || {});
  const [modules, setModules] = useState(initialModules || []);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isSelectModuleModalOpen, setSelectModuleModalOpen] = useState(false);
  const [prevSettings, setPrevSettings] = useState({});
  const [tempWidth, setTempWidth] = useState(width);
  const [prevWidth, setPrevWidth] = useState(width);

  useEffect(() => {
    setSettings(initialSettings || {});
  }, [initialSettings]);

  useEffect(() => {
    setModules(initialModules || []);
  }, [initialModules]);

  // Öffnet das Settings Modal und speichert vorherige Zustände
  const openSettings = () => {
    setPrevSettings({ ...settings });
    setPrevWidth(width);
    setTempWidth(width);
    setSettingsOpen(true);
  };

  // Schließt das Settings Modal und stellt vorherige Zustände wieder her
  const closeSettings = () => {
    setSettings({ ...prevSettings });
    setTempWidth(prevWidth);
    setSettingsOpen(false);
  };

  // Speichert die Änderungen und aktualisiert die übergeordnete Komponente
  const saveSettings = () => {
    setSettingsOpen(false);
    if (updateColumnSettings) {
      updateColumnSettings(settings);
    }
    if (tempWidth !== prevWidth) {
      updateColumnWidth(tempWidth);
    }
  };

  // Öffnet das Modal zur Modulauswahl (für leere Column)
  const openSelectModuleModal = () => setSelectModuleModalOpen(true);

  // Schließt das Modal zur Modulauswahl
  const closeSelectModuleModal = () => setSelectModuleModalOpen(false);

  // Fügt ein Modul hinzu (für leere Column)
  const handleSelectModule = (moduleName) => {
    const newModule = { id: Date.now(), type: moduleName, settings: {} };
    const updatedModules = [...modules, newModule];
    setModules(updatedModules);
    if (updateColumnModules) {
      updateColumnModules(updatedModules);
    }
    closeSelectModuleModal();
  };

  // Fügt ein Modul unterhalb eines bestehenden Moduls hinzu
  const addModuleBelow = (moduleId, moduleName) => {
    const newModule = { id: Date.now(), type: moduleName, settings: {} };
    const moduleIndex = modules.findIndex((module) => module.id === moduleId);
    const updatedModules = [...modules];
    updatedModules.splice(moduleIndex + 1, 0, newModule);
    setModules(updatedModules);
    if (updateColumnModules) {
      updateColumnModules(updatedModules);
    }
  };

  // Dupliziert ein Modul
  const duplicateModule = (moduleId) => {
    const moduleToDuplicate = modules.find((module) => module.id === moduleId);
    if (moduleToDuplicate) {
      const newModule = {
        ...moduleToDuplicate,
        id: Date.now(),
        settings: { ...moduleToDuplicate.settings },
      };
      const moduleIndex = modules.findIndex((module) => module.id === moduleId);
      const updatedModules = [...modules];
      updatedModules.splice(moduleIndex + 1, 0, newModule);
      setModules(updatedModules);
      if (updateColumnModules) {
        updateColumnModules(updatedModules);
      }
    }
  };

  // Löscht ein Modul
  const deleteModule = (moduleId) => {
    const updatedModules = modules.filter((module) => module.id !== moduleId);
    setModules(updatedModules);
    if (updateColumnModules) {
      updateColumnModules(updatedModules);
    }
  };

  // Aktualisiert die Einstellungen eines Moduls
  const updateModuleSettings = (moduleId, newSettings) => {
    const updatedModules = modules.map((module) =>
      module.id === moduleId ? { ...module, settings: newSettings } : module
    );
    setModules(updatedModules);
    if (updateColumnModules) {
      updateColumnModules(updatedModules);
    }
  };

  // Handhabt Änderungen der Einstellungen
  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  // Handhabt Änderungen der Spaltenbreite innerhalb des Modals
  const handleWidthChange = (newWidth) => {
    setTempWidth(newWidth);
  };

  const settingsComponents = {
    Inhalt: (
      <>
        <ColumnWidthSettings width={tempWidth} onChange={handleWidthChange} />
        <LinkSettings settings={settings} onChange={handleSettingsChange} />
        <BackgroundSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Design: (
      <>
        <SpacingSettings settings={settings} onChange={handleSettingsChange} />
        <BorderSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Erweitert: (
      <>
        <CSSIdClassSettings settings={settings} onChange={handleSettingsChange} />
        <CustomCSSSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
  };

  return (
    <div
      className={`column ${settings.className || ''}`}
      id={settings.id || undefined}
      style={{
        backgroundColor: settings.backgroundColor,
        backgroundImage: settings.backgroundImage ? `url(${settings.backgroundImage})` : undefined,
        marginTop: settings.marginTop,
        marginBottom: settings.marginBottom,
        marginLeft: settings.marginLeft,
        marginRight: settings.marginRight,
        paddingTop: settings.paddingTop,
        paddingBottom: settings.paddingBottom,
        paddingLeft: settings.paddingLeft,
        paddingRight: settings.paddingRight,
        borderWidth: settings.borderWidth,
        borderStyle: settings.borderStyle,
        borderColor: settings.borderColor,
        borderRadius: settings.borderRadius,
        boxShadow: settings.boxShadow,
      }}
    >
      {/* AddElementButton anzeigen, wenn keine Module vorhanden sind */}
      {modules.length === 0 ? (
        <AddElementButton
          onClick={openSelectModuleModal}
          label="Add Module"
          color="#FFD335"
          className="centeredAddElementButton"
        />
      ) : (
        modules.map((module) => (
          <div key={module.id} className="module-container">
            {module.type === 'TestModule' && (
              <TestModule
                moduleId={module.id}
                settings={module.settings}
                updateModuleSettings={(newSettings) =>
                  updateModuleSettings(module.id, newSettings)
                }
                onDuplicate={() => duplicateModule(module.id)}
                onDelete={() => deleteModule(module.id)}
                addModuleBelow={addModuleBelow}
              />
            )}
            {/* Weitere Module hier hinzufügen */}
          </div>
        ))
      )}

      {/* ElementToolbox */}
      <ElementToolbox
        onOpenSettings={openSettings}
        onDelete={deleteColumn}
        color="#FFD335"
        className="column-toolbox"
      />

      {/* Modals */}
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={closeSettings}
        onSave={saveSettings}
        settingsComponents={settingsComponents}
      />
      <SelectModuleModal
        isOpen={isSelectModuleModalOpen}
        onClose={closeSelectModuleModal}
        onSelectModule={handleSelectModule}
      />
    </div>
  );
};

export default Column;
