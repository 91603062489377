import React from 'react';
import DesktopViewButton from '../../../PageBuilder/Buttons/DesktopViewButton/DesktopViewButton';
import TabletViewButton from '../../../PageBuilder/Buttons/TabletViewButton/TabletViewButton';
import SmartphoneViewButton from '../../../PageBuilder/Buttons/SmartphoneViewButton/SmartphoneViewButton';
import './ToolbarBuilderResponsiveViews.css'; // Für das Layout-Styling

const ToolbarBuilderResponsiveViews = ({ activateDesktopView, activateTabletView, activateSmartphoneView }) => {
    return (
        <div className="responsiveviews">
            <DesktopViewButton 
                className="responsive-view-button" 
                onClick={activateDesktopView} 
            />
            <TabletViewButton 
                className="responsive-view-button" 
                onClick={activateTabletView} 
            />
            <SmartphoneViewButton 
                className="responsive-view-button" 
                onClick={activateSmartphoneView} 
            />
        </div>
    );
};

export default ToolbarBuilderResponsiveViews;
