// src/components/AdminDashboard/RightSidebar/RightSidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '../IconButton/IconButton';
import { faFileAlt, faBars, faPalette, faImages } from '@fortawesome/free-solid-svg-icons';
import './RightSidebar.css'; // Optional für Styling

function RightSidebar({ buttons, isOpen }) {
  return (
    <div className={`right-sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="right-sidebar-buttons">
        {buttons.map((button, index) => (
          <IconButton
            key={index}
            icon={button.icon}
            onClick={button.onClick}
            ariaLabel={button.ariaLabel}
            title={button.title}
            className={button.className}
          />
        ))}
      </div>
    </div>
  );
}

export default RightSidebar;