// src/components/AdminDashboard/LeftSidebar/LeftSidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faFileAlt,
  faNewspaper,
  faTags,
  faImages,
  faUsers,
  faUserShield,
  faCog,
  faShieldAlt,
  faDatabase,
  faChartLine,
  faToolbox,
  faPalette
} from '@fortawesome/free-solid-svg-icons';
import './LeftSidebar.css'; // Optional für Styling

function LeftSidebar({ isOpen, onLinkClick }) {
  return (
    <div className={`left-sidebar ${isOpen ? 'open' : 'closed'}`}>

      {/* Dashboard Kategorie */}
      <h2 className="left-sidebar-category">Dashboard</h2>
      <ul>
        <li>
          <NavLink
            to="/admin/overview"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faTachometerAlt} /> <span>Übersicht</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/statistics"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faChartLine} /> <span>Statistiken</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/recent-activity"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faToolbox} /> <span>Aktuelle Aktivitäten</span>
          </NavLink>
        </li>
      </ul>

      {/* Inhalte verwalten Kategorie */}
      <h2 className="left-sidebar-category">Inhalte verwalten</h2>
      <ul>
        <li>
          <NavLink
            to="/admin/pages"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faFileAlt} /> <span>Seiten</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/posts"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faNewspaper} /> <span>Beiträge</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/categories"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faTags} /> <span>Kategorien</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/tags"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faTags} /> <span>Tags</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/media"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faImages} /> <span>Medien</span>
          </NavLink>
        </li>
      </ul>

      {/* Benutzer verwalten Kategorie */}
      <h2 className="left-sidebar-category">Benutzer verwalten</h2>
      <ul>
        <li>
          <NavLink
            to="/admin/users"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faUsers} /> <span>Benutzer</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/roles"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faUserShield} /> <span>Rollen</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/permissions"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faUserShield} /> <span>Berechtigungen</span>
          </NavLink>
        </li>
      </ul>

      {/* Einstellungen Kategorie */}
      <h2 className="left-sidebar-category">Einstellungen</h2>
      <ul>
        <li>
          <NavLink
            to="/admin/general-settings"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faCog} /> <span>Allgemein</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/appearance"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faPalette} /> <span>Design</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/plugins"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faCog} /> <span>Plugins</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/seo-settings"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faCog} /> <span>SEO-Einstellungen</span>
          </NavLink>
        </li>
      </ul>

      {/* Sicherheit Kategorie */}
      <h2 className="left-sidebar-category">Sicherheit</h2>
      <ul>
        <li>
          <NavLink
            to="/admin/firewall"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faShieldAlt} /> <span>Firewall</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/backup"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faDatabase} /> <span>Backup</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/audit-logs"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faShieldAlt} /> <span>Audit-Logs</span>
          </NavLink>
        </li>
      </ul>

      {/* Berichte Kategorie */}
      <h2 className="left-sidebar-category">Berichte</h2>
      <ul>
        <li>
          <NavLink
            to="/admin/website-statistics"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faChartLine} /> <span>Website-Statistiken</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/traffic-reports"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faChartLine} /> <span>Verkehrsberichte</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/user-reports"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faChartLine} /> <span>Nutzerberichte</span>
          </NavLink>
        </li>
      </ul>

      {/* Tools Kategorie */}
      <h2 className="left-sidebar-category">Tools</h2>
      <ul>
        <li>
          <NavLink
            to="/admin/database-management"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faDatabase} /> <span>Datenbankverwaltung</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/api-settings"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faCog} /> <span>API-Einstellungen</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/developer-tools"
            onClick={onLinkClick}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            <FontAwesomeIcon className="left-sidebar-icon" icon={faToolbox} /> <span>Entwicklerwerkzeuge</span>
          </NavLink>
        </li>
      </ul>

    </div>
  );
}

export default LeftSidebar;
