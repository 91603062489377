// Modal.js
import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import './Modal.css';

const Modal = ({ isOpen, onClose, title, children, draggable = false, tabs }) => {
    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsFadingOut(false);
        }
    }, [isOpen]);

    if (!isOpen && !isFadingOut) return null;

    const handleClose = () => {
        setIsFadingOut(true);
        setTimeout(() => {
            setIsFadingOut(false);
            onClose();
        }, 200); // Dauer der fadeOut-Animation in ms
    };

    const modalContent = (
        <div className={`modal-content ${isFadingOut ? 'fade-out' : ''}`}>
            <div className="modal-sticky-header">
                <div className="modal-header">
                    <h2>{title}</h2>
                    <button className="close-button" onClick={handleClose}>✖</button>
                </div>
                {tabs && (
                    <div className="modal-tabs">
                        {tabs}
                    </div>
                )}
            </div>
            {children}
        </div>
    );

    return draggable ? (
        <Draggable handle=".modal-header" defaultPosition={{ x: -300, y: -100 }}>
            <div className="modal-wrapper">
                {modalContent}
            </div>
        </Draggable>
    ) : (
        <div className="modal-wrapper">
            {modalContent}
        </div>
    );
};

export default Modal;
