// OpenElementSettingsButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import './OpenElementSettingsButton.css';

const OpenElementSettingsButton = ({ onClick, color, className }) => {
    return (
        <button
            onClick={onClick}
            style={{ backgroundColor: color }}
            className={`openElementSettingsButton ${className || ''}`}
        >
            <FontAwesomeIcon icon={faCog} /> {/* Icon statt Label */}
        </button>
    );
};

export default OpenElementSettingsButton;
