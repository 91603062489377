// SmartphoneViewButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import './SmartphoneViewButton.css';

const SmartphoneViewButton = ({ onClick, color, className }) => {
    return (
        <button
            onClick={onClick}
            style={{ backgroundColor: color }}
            className={`smartphoneViewButton ${className || ''}`}
        >
            <FontAwesomeIcon icon={faMobileAlt} />
        </button>
    );
};

export default SmartphoneViewButton;
