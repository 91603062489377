// ToolbarTools.js
import React from 'react';
import SwitchDevViewButton from '../../../PageBuilder/Buttons/SwitchDevViewButton/SwitchDevViewButton';
import KeyboardShortcutsButton from '../../../PageBuilder/Buttons/KeyboardShortcutsButton/KeyboardShortcutsButton';
import './ToolbarBuilderTools.css'; // Für das Layout-Styling

const ToolbarTools = () => {
    return (
        <div className="toolbartools">

            <SwitchDevViewButton className="tool-button" />
            <KeyboardShortcutsButton className="tool-button" />
            <KeyboardShortcutsButton className="tool-button" />
            <KeyboardShortcutsButton className="tool-button" />
            <KeyboardShortcutsButton className="tool-button" />
        </div>
    );
};

export default ToolbarTools;
