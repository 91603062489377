// CustomCSSSettings.js
import React from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const CustomCSSSettings = ({ settings, onChange }) => {
    const handleChange = (e) => {
        onChange({ ...settings, customCSS: e.target.value });
    };

    return (
        <AccordionWrapper title="Benutzerdefiniertes CSS">
            <div className="settingCategory customcss-settings">
                <p className="settingCategoryDescription">Eigenen CSS-Code schreiben.</p>
                <textarea
                    name="customCSS"
                    value={settings.customCSS || ''}
                    onChange={handleChange}
                    placeholder="Hier benutzerdefiniertes CSS eingeben"
                    rows="10"
                    style={{ width: '100%' }}
                ></textarea>
            </div>
        </AccordionWrapper>
    );
};

export default CustomCSSSettings;
