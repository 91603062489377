// src/components/PageBuilder/MainElements/Section/Section.js

import React, { useState, useEffect } from 'react';
import Row from '../Row/Row';
import AddButton from '../../Buttons/AddElementButton/AddElementButton';
import LayoutModal from '../../Modals/LayoutModal/LayoutModal';
import SettingsModal from '../../Modals/SettingsModal/SettingsModal';
import LinkSettings from '../../Modals/SettingsModal/Settings/LinkSettings/LinkSettings';
import BackgroundSettings from '../../Modals/SettingsModal/Settings/BackgroundSettings/BackgroundSettings';
import AdminLabelSettings from '../../Modals/SettingsModal/Settings/AdminLabelSettings/AdminLabelSettings';
import SpacingSettings from '../../Modals/SettingsModal/Settings/SpacingSettings/SpacingSettings';
import BorderSettings from '../../Modals/SettingsModal/Settings/BorderSettings/BorderSettings';
import CSSIdClassSettings from '../../Modals/SettingsModal/Settings/CSSIdClassSettings/CSSIdClassSettings';
import CustomCSSSettings from '../../Modals/SettingsModal/Settings/CustomCSSSettings/CustomCSSSettings';
import ElementToolbox from '../../ElementToolbox/ElementToolbox';
import './Section.css';

const Section = ({
  sectionId,
  sectionIndex,
  updateSectionRows,
  updateSectionSettings,
  title,
  addSection,
  deleteSection,
  duplicateSection,
  settings: initialSettings,
  rows: initialRows,
}) => {
  const [settings, setSettings] = useState(initialSettings || {});
  const [rows, setRows] = useState(initialRows || []);
  const [isModalOpen, setModalOpen] = useState(false);
  const [insertIndex, setInsertIndex] = useState(null);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [prevSettings, setPrevSettings] = useState({});

  useEffect(() => {
    setSettings(initialSettings || {});
  }, [initialSettings]);

  useEffect(() => {
    setRows(initialRows || []);
  }, [initialRows]);

  // Öffnet das Layout Modal und speichert, wo die neue Zeile eingefügt werden soll
  const addRowAt = (index) => {
    setInsertIndex(index);
    setModalOpen(true);
  };

  // Layout-Auswahl für eine neue Row bestätigen und hinzufügen
  const handleLayoutSelect = (selectedLayout) => {
    const newRow = {
      id: Date.now() + Math.random(),
      layout: selectedLayout,
      settings: {},
      columns: selectedLayout.split(' ').map((width) => ({
        id: Date.now() + Math.random(),
        width,
        settings: {},
        modules: [], // Initialize modules
      })),
    };
    const newRows = [...rows];
    if (insertIndex !== null) {
      newRows.splice(insertIndex, 0, newRow);
    } else {
      newRows.push(newRow);
    }
    setRows(newRows);
    updateSectionRows(newRows);
    setInsertIndex(null);
    setModalOpen(false);
  };

  const deleteRow = (id) => {
    const newRows = rows.filter((row) => row.id !== id);
    setRows(newRows);
    updateSectionRows(newRows);
  };

  // Funktionen für das Settings Modal
  const openSettings = () => {
    setPrevSettings({ ...settings });
    setSettingsOpen(true);
  };

  const closeSettings = () => {
    setSettings({ ...prevSettings });
    setSettingsOpen(false);
  };

  const saveSettings = () => {
    setSettingsOpen(false);
    if (updateSectionSettings) {
      updateSectionSettings(settings);
    }
  };

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  // Funktionen zum Aktualisieren der Row-Einstellungen
  const updateRowSettingsFunc = (rowId, newSettings) => {
    const newRows = rows.map((row) =>
      row.id === rowId ? { ...row, settings: newSettings } : row
    );
    setRows(newRows);
    updateSectionRows(newRows);
  };

  const updateRowColumns = (rowId, newColumns) => {
    const newRows = rows.map((row) =>
      row.id === rowId ? { ...row, columns: newColumns } : row
    );
    setRows(newRows);
    updateSectionRows(newRows);
  };

  // Funktion zum Duplizieren der Section
  const handleDuplicate = () => {
    const deepCopyWithNewIds = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => deepCopyWithNewIds(item));
      } else if (typeof obj === 'object' && obj !== null) {
        const newObj = { ...obj, id: Date.now() + Math.random() };
        for (const key in newObj) {
          if (key !== 'id') {
            newObj[key] = deepCopyWithNewIds(newObj[key]);
          }
        }
        return newObj;
      } else {
        return obj;
      }
    };

    const sectionData = {
      settings: { ...settings },
      rows: rows.map((row) => ({
        ...row,
        columns: row.columns.map((col) => ({
          ...col,
          modules: col.modules.map((mod) => ({ ...mod })),
        })),
      })),
    };

    const newSectionData = deepCopyWithNewIds(sectionData);
    duplicateSection(sectionIndex + 1, newSectionData);
  };

  // Definition der Unterkomponenten für die Tabs im Settings Modal
  const settingsComponents = {
    Inhalt: (
      <>
        <LinkSettings settings={settings} onChange={handleSettingsChange} />
        <BackgroundSettings settings={settings} onChange={handleSettingsChange} />
        <AdminLabelSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Design: (
      <>
        <SpacingSettings settings={settings} onChange={handleSettingsChange} />
        <BorderSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Erweitert: (
      <>
        <CSSIdClassSettings settings={settings} onChange={handleSettingsChange} />
        <CustomCSSSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
  };

  return (
    <div
      className={`section ${settings.className || ''}`}
      id={settings.id || undefined}
      style={{
        backgroundColor: settings.backgroundColor,
        backgroundImage: settings.backgroundImage
          ? `url(${settings.backgroundImage})`
          : undefined,
        marginTop: settings.marginTop,
        marginBottom: settings.marginBottom,
        marginLeft: settings.marginLeft,
        marginRight: settings.marginRight,
        paddingTop: settings.paddingTop,
        paddingBottom: settings.paddingBottom,
        paddingLeft: settings.paddingLeft,
        paddingRight: settings.paddingRight,
        borderWidth: settings.borderWidth,
        borderStyle: settings.borderStyle,
        borderColor: settings.borderColor,
        borderRadius: settings.borderRadius,
        boxShadow: settings.boxShadow,
      }}
    >
      {/* ElementToolbox */}
      <ElementToolbox
        onOpenSettings={openSettings}
        onDuplicate={handleDuplicate}
        onDelete={deleteSection}
        color="#FF5801"
        className="section-toolbox"
      />

      {/* Add Row Button oder Rows anzeigen */}
      {rows.length === 0 ? (
        <AddButton
          onClick={() => addRowAt(rows.length)}
          label="Add Row"
          color="#FF9400"
          className="centeredAddElementButton"
        />
      ) : (
        rows.map((row, index) => (
          <Row
            key={row.id}
            rowId={row.id}
            rowIndex={index}
            sectionId={sectionId}
            layout={row.layout}
            addRowAt={addRowAt}
            index={index}
            deleteRow={() => deleteRow(row.id)}
            duplicateRow={(rowData) => {
              const deepCopyWithNewIds = (obj) => {
                if (Array.isArray(obj)) {
                  return obj.map((item) => deepCopyWithNewIds(item));
                } else if (typeof obj === 'object' && obj !== null) {
                  const newObj = { ...obj, id: Date.now() + Math.random() };
                  for (const key in newObj) {
                    if (key !== 'id') {
                      newObj[key] = deepCopyWithNewIds(newObj[key]);
                    }
                  }
                  return newObj;
                } else {
                  return obj;
                }
              };
              const newRow = deepCopyWithNewIds(rowData);
              const newRows = [...rows];
              newRows.splice(index + 1, 0, newRow);
              setRows(newRows);
              updateSectionRows(newRows);
            }}
            settings={row.settings}
            columns={row.columns}
            updateRowSettings={(newSettings) => updateRowSettingsFunc(row.id, newSettings)}
            updateRowColumns={(newColumns) => updateRowColumns(row.id, newColumns)}
          />
        ))
      )}

      {/* Add Section Button */}
      <AddButton
        onClick={() => addSection(sectionIndex + 1)}
        label="Add Section"
        color="#FF5801"
        className="bottomAddElementButton bottomAddSectionButton"
      />

      {/* LayoutModal zur Layout-Auswahl */}
      <LayoutModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSelectLayout={handleLayoutSelect}
      />

      {/* SettingsModal zur Einstellung der Section */}
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={closeSettings}
        onSave={saveSettings}
        settingsComponents={settingsComponents}
      />
    </div>
  );
};

export default Section;
