// BorderSettings.js
import React from 'react';
import AccordionWrapper from '../../../../../Modal/AccordionWrapper/AccordionWrapper';

const BorderSettings = ({ settings, onChange }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({ ...settings, [name]: value });
    };

    return (
        <AccordionWrapper title="Border">
            <div className="settingCategory border-settings">
                <p className="settingCategoryDescription">Einen Rand für das gesamte Element definieren.</p>
                <div>
                    <label>Border Width:</label>
                    <input
                        type="text"
                        name="borderWidth"
                        value={settings.borderWidth || ''}
                        onChange={handleChange}
                        placeholder="z.B. 1px"
                    />
                </div>
                <div>
                    <label>Border Style:</label>
                    <select
                        name="borderStyle"
                        value={settings.borderStyle || 'none'}
                        onChange={handleChange}
                    >
                        <option value="none">None</option>
                        <option value="solid">Solid</option>
                        <option value="dashed">Dashed</option>
                        <option value="dotted">Dotted</option>
                        <option value="double">Double</option>
                        {/* Weitere Optionen */}
                    </select>
                </div>
                <div>
                    <label>Border Color:</label>
                    <input
                        type="color"
                        name="borderColor"
                        value={settings.borderColor || '#000000'}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Border Radius:</label>
                    <input
                        type="text"
                        name="borderRadius"
                        value={settings.borderRadius || ''}
                        onChange={handleChange}
                        placeholder="z.B. 5px"
                    />
                </div>
            </div>
        </AccordionWrapper>
    );
};

export default BorderSettings;
