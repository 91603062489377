// src/components/PageBuilder/MainElements/Module/Module.js

import React, { useState, useEffect } from 'react';
import ElementToolbox from '../../ElementToolbox/ElementToolbox';
import SettingsModal from '../../Modals/SettingsModal/SettingsModal';
import LinkSettings from '../../Modals/SettingsModal/Settings/LinkSettings/LinkSettings';
import BackgroundSettings from '../../Modals/SettingsModal/Settings/BackgroundSettings/BackgroundSettings';
import AdminLabelSettings from '../../Modals/SettingsModal/Settings/AdminLabelSettings/AdminLabelSettings';
import SpacingSettings from '../../Modals/SettingsModal/Settings/SpacingSettings/SpacingSettings';
import BorderSettings from '../../Modals/SettingsModal/Settings/BorderSettings/BorderSettings';
import CSSIdClassSettings from '../../Modals/SettingsModal/Settings/CSSIdClassSettings/CSSIdClassSettings';
import CustomCSSSettings from '../../Modals/SettingsModal/Settings/CustomCSSSettings/CustomCSSSettings';
import './Module.css';

const Module = ({
  moduleId,
  settings: initialSettings,
  updateModuleSettings,
  onDuplicate,
  onDelete,
  addModuleBelow,
  children,
}) => {
  const [settings, setSettings] = useState(initialSettings || {});
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [prevSettings, setPrevSettings] = useState({});

  useEffect(() => {
    setSettings(initialSettings || {});
  }, [initialSettings]);

  // Öffnet das SettingsModal und speichert den aktuellen Zustand
  const openSettings = () => {
    setPrevSettings({ ...settings });
    setSettingsOpen(true);
  };

  // Schließt das SettingsModal und stellt den vorherigen Zustand wieder her
  const closeSettings = () => {
    setSettings({ ...prevSettings });
    setSettingsOpen(false);
  };

  // Speichert die Änderungen aus dem SettingsModal
  const saveSettings = () => {
    setSettingsOpen(false);
    if (updateModuleSettings) {
      updateModuleSettings(settings);
    }
  };

  // Handhabt Änderungen der Einstellungen
  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  // Unterkomponenten für die Tabs im SettingsModal
  const settingsComponents = {
    Inhalt: (
      <>
        <LinkSettings settings={settings} onChange={handleSettingsChange} />
        <AdminLabelSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Design: (
      <>
        <BackgroundSettings settings={settings} onChange={handleSettingsChange} />
        <SpacingSettings settings={settings} onChange={handleSettingsChange} />
        <BorderSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
    Erweitert: (
      <>
        <CSSIdClassSettings settings={settings} onChange={handleSettingsChange} />
        <CustomCSSSettings settings={settings} onChange={handleSettingsChange} />
      </>
    ),
  };

  // Dynamische Stile basierend auf den Einstellungen
  const moduleStyle = {
    backgroundColor: settings.backgroundColor,
    backgroundImage: settings.backgroundImage
      ? `url(${settings.backgroundImage})`
      : undefined,
    marginTop: settings.marginTop,
    marginBottom: settings.marginBottom,
    marginLeft: settings.marginLeft,
    marginRight: settings.marginRight,
    paddingTop: settings.paddingTop,
    paddingBottom: settings.paddingBottom,
    paddingLeft: settings.paddingLeft,
    paddingRight: settings.paddingRight,
    borderWidth: settings.borderWidth,
    borderStyle: settings.borderStyle,
    borderColor: settings.borderColor,
    borderRadius: settings.borderRadius,
    boxShadow: settings.boxShadow,
  };

  return (
    <div className="module" style={moduleStyle}>
      {/* Toolbox mit Buttons für Einstellungen, Duplizieren und Löschen */}
      <ElementToolbox
        onOpenSettings={openSettings}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        color="#161820"
        className="module-toolbox"
      />

      {children}

      {/* SettingsModal */}
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={closeSettings}
        onSave={saveSettings}
        settingsComponents={settingsComponents}
      />
    </div>
  );
};

export default Module;
