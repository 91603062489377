// DuplicateElementButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons'; // Duplikat-Icon
import './DuplicateElementButton.css';

const DuplicateElementButton = ({ onClick, color, className }) => {
    return (
        <button
            onClick={onClick}
            style={{ backgroundColor: color }}
            className={`duplicateElementButton ${className || ''}`}
        >
            <FontAwesomeIcon icon={faClone} />
        </button>
    );
};

export default DuplicateElementButton;
